import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/base.css");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/accessories/accessories-context.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/anonymous-session-provider.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/cart/cart-context.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/context/product-search-context.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/context/tax-state-context.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/cookies/cookie-context.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/cookies/cookie-scripts.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/footer/client.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/header/client.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/app/components/header/header.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/components/banner/confetti-banner.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/apps/website/src/trpc/react.tsx");
import(/* webpackMode: "eager" */ "/home/userapp/node_modules/.pnpm/next-auth@5.0.0-beta.13_next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/next-auth/react.js");
import(/* webpackMode: "eager" */ "/home/userapp/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/userapp/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/userapp/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/userapp/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lilita_One\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"variable\":\"--font-secondary\"}],\"variableName\":\"lilita\"}");
import(/* webpackMode: "eager" */ "/home/userapp/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/home/userapp/packages/utils/src/hooks/use-is-past-noon.ts")