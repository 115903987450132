'use client';

import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

const ReactConfetti = dynamic(() => import('react-confetti'), { ssr: false });

export default function ConfettiBanner() {
  const [hasVisited, setHasVisited] = useState(true);

  useEffect(() => {
    const hasVisitedStore = localStorage.getItem('hasVisited');
    if (hasVisitedStore === null) {
      setHasVisited(false);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  return hasVisited ? null : (
    <ReactConfetti
      numberOfPieces={2000}
      className="z-[999999]"
      recycle={false}
      style={{ zIndex: 999999 }}
      run
      colors={['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93']}
    />
  );
}
