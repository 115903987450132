'use client';

import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import CookieConsent from './cookie-consent';

const defaultCookies = {
  analytical: false,
  marketing: false,
  preferences: false,
};

export interface CookieFields {
  analytical: boolean;
  marketing: boolean;
  preferences: boolean;
}

export interface CookieContextType {
  /* eslint-disable no-unused-vars */
  updateCookies: (_: CookieFields) => void;
  cookies: CookieFields;
}

export const CookieContext = createContext<CookieContextType>({
  updateCookies: () => {},
  cookies: defaultCookies,
});

export const useCookie = () => useContext<CookieContextType>(CookieContext);

const CookieProvider = ({ children }: { children: ReactNode }) => {
  const [cookies, setCookies] = useState(defaultCookies);
  const [consent, setConsent] = useState<boolean | null>(null);

  useEffect(() => {
    const storedPreferences = Cookies.get('cookie_settings');
    if (storedPreferences) {
      setCookies(JSON.parse(storedPreferences));
      setConsent(true);
    } else {
      setConsent(false);
    }
  }, []);

  const updateCookies = useCallback(async (cookies: CookieFields) => {
    setCookies(cookies);
    Cookies.set('cookie_settings', JSON.stringify(cookies), { expires: 365 });
    if (!consent)
      setTimeout(() => {
        setConsent(true);
      }, 200);
  }, []);

  const store = useMemo(
    () => ({
      cookies,
      updateCookies,
    }),
    [cookies, updateCookies]
  );

  return (
    <CookieContext.Provider value={store}>
      {children}
      <CookieConsent consent={consent === null ? true : consent} close={() => setConsent(true)} />
    </CookieContext.Provider>
  );
};

export default CookieProvider;
