'use client';

import { GA_TAG_ID, GA_TRACKING_ID } from '@/app/gtag';
import { useEffect } from 'react';

const GoogleAnalytics = ({ show }: { show: boolean }) => {
  useEffect(() => {
    const gtagScriptId = 'google-analytics';
    const gtmScriptId = 'google-tag-manager';
    const gtagScriptSrc = `https://www.googletagmanager.com/gtag/js?id=${GA_TAG_ID}&l=dataLayer&cx=c`;
    const gtmScriptSrc = `https://www.googletagmanager.com/gtm.js?id=${GA_TRACKING_ID}`;

    const addScripts = () => {
      if (!document.getElementById(gtagScriptId)) {
        const gtagScript = document.createElement('script');
        gtagScript.id = gtagScriptId;
        gtagScript.src = gtagScriptSrc;
        gtagScript.async = true;
        document.head.appendChild(gtagScript);

        const gtagInlineScript = document.createElement('script');
        gtagInlineScript.id = 'gtag-init';
        gtagInlineScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_TRACKING_ID}', {
            page_path: window.location.pathname,
          });
        `;
        document.head.appendChild(gtagInlineScript);
      }

      if (!document.getElementById(gtmScriptId)) {
        const gtmScript = document.createElement('script');
        gtmScript.id = gtmScriptId;
        gtmScript.src = gtmScriptSrc;
        gtmScript.async = true;
        document.head.appendChild(gtmScript);

        const gtmInlineScript = document.createElement('script');
        gtmInlineScript.id = 'gtm-init';
        gtmInlineScript.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GA_TRACKING_ID}');
        `;
        document.head.appendChild(gtmInlineScript);
      }
    };

    const removeScripts = () => {
      const scriptsToRemove = [gtagScriptId, 'gtag-init', gtmScriptId, 'gtm-init'];

      scriptsToRemove.forEach((id) => {
        const script = document.getElementById(id);
        if (script) {
          script.remove();
        }
      });
    };

    if (show) {
      addScripts();
    } else {
      removeScripts();
    }

    // Cleanup function to ensure scripts are removed when component unmounts
    return () => {
      removeScripts();
    };
  }, [show]);

  return null;
};

export default GoogleAnalytics;
