import React from 'react';
import { sanitizeHtml } from '@av/utils';

export const setHtml = (html = '') => ({
  dangerouslySetInnerHTML: { __html: sanitizeHtml(html) },
});

export const Inner: React.FC<React.HTMLAttributes<HTMLDivElement> & { html: string }> = ({
  html,
  ...props
}) => {
  return <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} {...props} />;
};
