'use client';

import React, { useEffect, useRef } from 'react';
import { SearchIcon, X } from 'lucide-react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { Input } from '@av/ui/form';
import { cn } from '@av/utils';

const Search = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const query = searchParams.get('query') || '';
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleInputChange = (value: string, path: string, timeout = 0) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    const newSearchParams = new URLSearchParams(searchParams);
    if (!value) {
      newSearchParams.delete('query');
      router.push(`${path}?${newSearchParams.toString()}`, undefined);
    } else {
      debounceTimerRef.current = setTimeout(() => {
        newSearchParams.set('query', value);
        router.push(`${path}?${newSearchParams.toString()}`, undefined);
      }, timeout);
    }
  };

  useEffect(() => {
    if (!query && inputRef.current) inputRef.current.value = '';

    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [query]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleInputChange(inputRef.current?.value || '', '/assortiment');
    }
  };

  return (
    <div className="relative">
      <Input
        ref={inputRef}
        placeholder="Artikel zoeken.."
        defaultValue={query}
        onChange={(e) =>
          handleInputChange(e.target.value, pathname, pathname.includes('/assortiment') ? 0 : 1000)
        }
        onKeyDown={handleKeyDown}
        className="h-[40px] w-full rounded-full bg-secondary px-5 text-base font-medium placeholder:font-medium placeholder:text-description sm:text-sm md:max-w-48"
      />
      {!pathname.includes('/assortiment') && query && (
        <div
          className={cn(
            'max-md:hidden absolute top-[calc(100%+0.5rem)] left-1/2 -translate-x-1/2 rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md w-72 max-w-full flex space-x-2 transition-all',
            query ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2'
          )}
        >
          <div>
            Druk op{' '}
            <code className="rounded bg-gray-100 px-1 py-0.5 font-semibold text-gray-700">
              Enter
            </code>{' '}
            om door het hele assortiment te zoeken!
          </div>
        </div>
      )}
      <button
        type="button"
        onClick={() => handleInputChange(inputRef.current?.value || '', '/assortiment')}
        className="absolute right-3 top-1/2 -translate-y-1/2"
        aria-label="Zoeken"
      >
        <SearchIcon className="size-6 rounded-full bg-secondary px-1" />
      </button>
      {!!query && (
        <X
          onClick={() => {
            handleInputChange('', pathname);
          }}
          className="absolute right-8 top-1/2 size-6 -translate-y-1/2 cursor-pointer rounded-full bg-secondary px-1"
        />
      )}
    </div>
  );
};

export default Search;
