'use client';

import { Button } from '@av/ui/button';
import { ShoppingBagIcon } from 'lucide-react';
import React from 'react';
import { useCart } from '../cart/cart-context';

const ShoppingBag = () => {
  const { openCart, cartItemsCount } = useCart();

  return (
    <div className="relative">
      {cartItemsCount > 0 && (
        <div className="absolute -right-1 -top-1 z-10 flex size-[18px] items-center justify-center rounded-full bg-primary text-[11px] font-semibold text-white ">
          {cartItemsCount}
        </div>
      )}
      <Button variant="secondary" size="icon" onClick={openCart}>
        <ShoppingBagIcon className="size-5" />
        <span className="sr-only">Winkelwagen</span>
      </Button>
    </div>
  );
};

export default ShoppingBag;
