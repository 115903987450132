import { cn } from '@av/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';

export const sectionVariants = cva('text-base font-medium leading-6 ', {
  variants: {
    spacing: {
      default: 'mx-auto max-w-container',
      none: '',
    },
    padding: {
      default: 'px-4 py-10 md:px-4',
      x: 'px-4 sm:px-6 md:px-4',
      y: 'py-10 md:py-20 lg:py-24',
      none: '',
    },
    gap: {
      default: 'gap-10 sm:gap-16 md:gap-20 lg:gap-24',
      none: '',
      element: 'gap-4 md:gap-7',
    },
  },
  defaultVariants: {
    spacing: 'default',
    padding: 'default',
    gap: 'default',
  },
});

export interface SectionProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof sectionVariants> {
  as?: 'header' | 'div';
  maxWidth?: number | string;
  innerId?: string;
  parentClassName?: string;
}

const Section: React.FC<SectionProps> = ({
  children,
  spacing,
  padding,
  gap,
  as,
  className,
  maxWidth,
  innerId,
  parentClassName,
  ...props
}) => {
  const Comp = as || 'section';

  return (
    <Comp {...props} className={cn(parentClassName, 'relative')}>
      <div
        className={cn(
          sectionVariants({ spacing: maxWidth ? 'none' : spacing, padding, gap }),
          className
        )}
        style={maxWidth ? { maxWidth } : undefined}
        id={innerId}
      >
        {children}
      </div>
    </Comp>
  );
};
Section.displayName = 'Section';

export { Section };
