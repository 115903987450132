import React from 'react';
import GoogleAnalytics from './google-analytics';

const AnalyticsCookies = ({ show }: { show: boolean }) => {
  return (
    <>
      <GoogleAnalytics show={show} />
    </>
  );
};

export default AnalyticsCookies;
