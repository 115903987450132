'use client';

import type { ReactNode } from 'react';
import { signIn, useSession } from 'next-auth/react';

export const AnonymousSessionProvider = ({ children }: { children: ReactNode }) => {
  useSession({
    required: true,
    onUnauthenticated() {
      setTimeout(() => {
        signIn('anonymous', { redirect: false });
      }, 1000);
    },
  });

  return <>{children}</>;
};
