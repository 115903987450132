'use client';

import { NavigationItem } from '@av/ui/navigation-menu';
import React, { createContext, useContext, useMemo } from 'react';

const ProductSearchContext = createContext({
  categoryItems: [] as NavigationItem[],
});

interface ProductSearchContextType {
  categoryItems: NavigationItem[];
}

export const useProductSearch = () => useContext<ProductSearchContextType>(ProductSearchContext);

const ProductSearchProvider = ({
  children,
  categoryItems,
}: ProductSearchContextType & { children: React.ReactNode }) => {
  const store = useMemo(() => ({ categoryItems }), [categoryItems]);

  return <ProductSearchContext.Provider value={store}>{children}</ProductSearchContext.Provider>;
};

export default ProductSearchProvider;
